import React from "react"
import styled from "styled-components";
import ContactForm from "../../components/landing/ContactForm";
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import background from "../../images/source/fondo_ing.png"
import background_small from "../../images/source/fondo_ing_small.png"

const IngenieriaSoftware = ({ location }) => (
    <Layout location={location}>
        <SEO title="Ingeniería de software" />
        <Styled>
            <div className="info">
                <h3>Ingeniería de Software 2</h3>
                <p>El ingenio empieza por entender.</p>
                <iframe width="350" height="196" src="https://www.youtube.com/embed/xMlZuii2or8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <div className="background">
                    <div className="text">
                        Diseñamos y desarrollamos soluciones de software end-to-end para acompañar iniciativas, necesidades y proyectos de transformación digital de las empresas.
                    </div>
                </div>
            </div>
            <div className="contact">
                <ContactForm page="Ingeniería de software" location={location} title={'Contáctanos para conocer más casos y soluciones.'} />
            </div>
        </Styled>
    </Layout>
)

const Styled = styled.div`
height: 100%;
min-height: 750px;
display:flex;
background-image: url("${background}");
flex-wrap: wrap;
flex-direction: row;
background-position-x: 0px;
justify-content: center;
background-repeat-y: no-repeat;

@media (max-width: 1100px) {
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-position-x: -410px !important;
}

@media (max-width: 1200px) {
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-position-x: -310px !important;
}

@media (max-width: 1600px) {
    background-position-y: 30px;
    background-repeat: no-repeat;
    background-position-x: -210px;
}


@media (max-width: 780px) {
    flex-direction: column-reverse;
    background: none;
}

 .info {

    margin-top: 60px;
    @media (max-width: 780px) {
        margin-top: 20px;
    }

    iframe {
        @media (max-width: 780px) {
            width: 375px;
            height: 220px;
            margin-bottom: 0px !important;      
        }
    }
    @media (max-width: 780px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h3 {
        height: 128.51px;
        width: 350px;
        color: #000000;
        font-family: "Century Gothic";
        font-size: 42px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 48px;
        margin-bottom: 0;
    }

    p {
        width: 350px;
        color: #007881;
        font-family: "Century Gothic";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
    }

    .text {
        margin-top: 35px;
        height: 192px;
        width: 350px;
        color: #FFFFFF;
        font-family: "Century Gothic";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
        
        @media (max-width: 780px) {
            margin: 0 auto;
            height: 350px;
        }

    }

    .background {
        @media (max-width: 780px) {
            width: 100%;
            background-image: url("${background_small}");
        }
    }

 }

 .contact {
    margin-top: 70px;
    padding: 40px;
    height: 598px;
    width: 573px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
    margin-left: 110px;

    @media (max-width: 900px) {
        margin-left: 0px;
    }

    @media (max-width: 780px) {
        box-shadow: none;
        border-radius: 0;
        margin-top: 0px;
        padding: 20px;
        width: 100%;
    }
 }

`


export default IngenieriaSoftware;